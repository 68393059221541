import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, Fab, FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField } from '@mui/material';
import { useState } from 'react';

import { CheckCircle, Delete, Save } from '@mui/icons-material';
import userEvent from '@testing-library/user-event';
import { isAdmin } from '../auth/utils';
import { useAuth0 } from '@auth0/auth0-react';

export default function ProjectSettings({ project, updateProjectSettings }) {

    const { user, getAccessTokenSilently } = useAuth0();
    const [projectPrompt, setProjectPrompt] = useState(project.project_prompt);
    const [artifactTypes, setArtifactTypes] = useState([...project.artifact_types]);

    const [edited, setEdited] = useState(false);

    const createNewArtifactType = (name) => {
        console.log('Creating new artifact type: ', name);
        const newArtifact = { name: name, content_prompt: '', description: '' };
        const newArtifactTypes = [newArtifact, ...artifactTypes];
        setArtifactTypes(newArtifactTypes);
    };



    const updateArtifactTypePrompt = (artifactTypeIndex, promptKey, newPrompt) => {
        var newArtifactTypes = [...artifactTypes];
        newArtifactTypes[artifactTypeIndex].prompts[promptKey] = newPrompt;
        setArtifactTypes(newArtifactTypes);
        console.log(`updated prompt for ${newArtifactTypes[artifactTypeIndex].name}: ${newArtifactTypes[artifactTypeIndex].prompts[promptKey].prompt}`);
    };

    const updateArtifactTypeDescription = (index, desc) => {
        console.log('Updating index: ', index, ' desc: ', desc);
        var newArtifactTypes = [...artifactTypes];
        newArtifactTypes[index].description = desc;
        setArtifactTypes(newArtifactTypes);
    };

    const [newArtifactTypeOpen, setNewArtifactTypeOpen] = useState(false);
    const [newArtifactTypeName, setNewArtifactTypeName] = useState('');
    const handleNewArtifactTypeOpen = () => {
        setNewArtifactTypeOpen(true);
    };

    const handleHideContentType = async (index) => {
        if (window.confirm('Are you sure you want to hide this content type?')) {
            artifactTypes[index].is_hidden = true;
            updateProjectSettings(projectPrompt, artifactTypes);
        }
    };

    return (
        <>
            <Dialog open={newArtifactTypeOpen}>
                <DialogContent>
                    <Stack direction="row" spacing={2}>
                        <Typography variant='subtitle1'>New Content Type</Typography>
                    </Stack>
                    <TextField
                        label="Name"
                        fullWidth
                        value={newArtifactTypeName}
                        onChange={(e) => setNewArtifactTypeName(e.target.value)} />
                    <DialogActions>
                        <Button onClick={() => setNewArtifactTypeOpen(false)}>Cancel</Button>
                        <Button onClick={() => { createNewArtifactType(newArtifactTypeName); setNewArtifactTypeOpen(false) }}>Save</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Box sx={{ overflowY: 'scroll', height: 'auto' }}>
                <Stack
                    direction="column"
                    spacing={2}
                >
                    <Typography variant='h6'>Project Information</Typography>
                    <TextField
                        label="Project Prompt"
                        fullWidth
                        multiline
                        sx={{ backgroundColor: 'white', marginBottom: '10px' }}
                        minRows={6}
                        maxRows={12}
                        value={projectPrompt}
                        onChange={(e) => {
                            setEdited(true);
                            setProjectPrompt(e.target.value);
                        }} />
                    <Stack direction='column'><Typography variant='h6'>Content Types</Typography><Button onClick={handleNewArtifactTypeOpen}>Add New Type</Button></Stack>
                    {artifactTypes && artifactTypes.filter(item => !item.is_hidden).map((artifact_type, artifactTypeIndex) => {
                        return (
                            <Card>
                                <CardContent>
                                    <Stack key={artifact_type._id} direction="column" spacing={2}>

                                        <Stack direction="row">
                                            <Typography variant='subtitle1' fontWeight={'bold'} textAlign={'left'} marginBottom={'5px'}>{artifact_type.name}</Typography>
                                            {isAdmin(user) && <IconButton onClick={() => handleHideContentType(artifactTypeIndex)}><Delete /></IconButton>}
                                        </Stack>
                                        <TextField
                                            label="Description (for user understanding. does not affect the AI)"
                                            fullWidth
                                            value={artifact_type.description}
                                            onChange={(e) => {
                                                setEdited(true);
                                                updateArtifactTypeDescription(artifactTypeIndex, e.target.value);
                                                setArtifactTypes([...artifactTypes]);
                                            }} />

                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={artifact_type.is_multishot}/>} label="Generate with multiple passes" />
                                        </FormGroup>

                                        {artifact_type.prompts && Object.keys(artifact_type.prompts).map((key) => {
                                            return (
                                                <TextField
                                                    label={key}
                                                    fullWidth
                                                    multiline
                                                    minRows={6}
                                                    maxRows={12}
                                                    value={artifactTypes[artifactTypeIndex].prompts[key] || ''}
                                                    onChange={(e) => {
                                                        setEdited(true);
                                                        console.log(e.target.value);
                                                        updateArtifactTypePrompt(artifactTypeIndex, key, e.target.value);
                                                    }}
                                                />
                                            );
                                        }
                                        )}
                                    </Stack>
                                </CardContent>
                            </Card>
                        )
                    })}
                    <div style={{ bottom: '40px', right: '40px', position: 'absolute' }}>
                        <Fab
                            variant="extended"
                            color="primary"
                            disabled={!edited}
                            onClick={() => {
                                updateProjectSettings(projectPrompt, artifactTypes);
                                setEdited(false);
                            }}
                        >
                            <CheckCircle sx={{ mr: 1 }} />
                            Save Changes
                        </Fab>
                    </div>

                </Stack>
            </Box>
        </>
    )
}