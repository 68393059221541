import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, ToggleButtonGroup, Card, CardActionArea, CardContent, CardMedia, ListItemText, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useContext } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import { MoreVert } from '@mui/icons-material';
import { ViewContext } from '../ViewContext';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export default function ArtifactList({ project, onArtifactsChanged }) {

  const { setActiveArtifactId } = useContext(ViewContext);

  const { getAccessTokenSilently } = useAuth0();

  function onClickArtifact(id) {
    console.log('Clicked on artifact:', id);
    setActiveArtifactId(id);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const contextMenuOpen = Boolean(anchorEl);
  const [artifactIdForContextMenu, setArtifactIdForContextMenu] = useState(null);
  const [selectedView, setSelectedView] = useState('grid');

  const handleContextMenuClick = (event, artifactId) => {
    setArtifactIdForContextMenu(artifactId);
    console.log('Context menu for ', artifactId);
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };



  const handleDeleteArtifact = async () => {
    try {
      if (window.confirm('Are you sure you want to delete this document?')) {
        console.log(`OK! Deleting artifact with ID: ${artifactIdForContextMenu}`);

        const accessToken = await getAccessTokenSilently();
        const headers = { "content-type": "application/json", Authorization: `Bearer ${accessToken} ` }
        const url = `${process.env.REACT_APP_API_URL}/artifacts/${artifactIdForContextMenu}`;

        axios.delete(url, { headers: headers })
          .then(() => {
            console.log('artifact deleted. Did it refresh?');
          })
          .then(() => onArtifactsChanged())
      }
    }
    finally {
      handleContextMenuClose();
    }
  }

  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: false
    }
  ];

  return (
    <>
      <Box
        component="main"
        sx={{

          flexGrow: 1,
          overflow: 'auto'
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          
          {/*}
          <ToggleButtonGroup value={selectedView}
            onChange={(event, newValue) => setSelectedView(newValue)}>
            <Button value='list'><ListIcon/></Button>
            <Button value='grid'><GridViewIcon/></Button>
          </ToggleButtonGroup>
          */}
          
          {selectedView === 'list' && project && project.artifacts && 
            <DataGrid
            rows={project.artifacts}
            columns={dataGridColumns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[50]}
            //onRowSelectionModelChange={(newSelection) => {
            //  setSelection(newSelection);
            //}}
          />
          
          }


          {selectedView === 'grid' &&
            <Grid container spacing={1}>
              {project && project.artifacts && project.artifacts.map((artifact) => (
                <>
                  <Grid item lg={3}>
                    <Card sx={{
                      minWidth: 190,
                      maxWidth: 200,
                      ':hover': {
                        bgcolor: 'grey.200',
                      }
                    }}>
                      <CardActionArea onClick={() => onClickArtifact(artifact.id)}>
                        <CardMedia
                          sx={{
                            height: 100,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}>
                          <ArticleIcon sx={{ color: 'gray' }} />
                        </CardMedia>
                      </CardActionArea>
                      <CardContent>
                        <Grid container spacing={0}>
                          <Grid item xs={10}>
                            <CardActionArea onClick={() => onClickArtifact(artifact.id)}>
                              <ListItemText
                                sx={{ textAlign: 'left' }}
                                primary={artifact.name || "Document"}
                                secondary={artifact.type.name || ""}>
                              </ListItemText>
                            </CardActionArea>   </Grid>
                          <Grid item xs={1} >
                            <IconButton onClick={(e) => handleContextMenuClick(e, artifact.id)}><MoreVert /> </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>


                    </Card>

                  </Grid>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={contextMenuOpen}
                    onClose={handleContextMenuClose}
                  >
                    <MenuItem onClick={handleDeleteArtifact}>Delete</MenuItem>
                  </Menu>
                </>

              ))}

            </Grid>
          }
        </Container>

      </Box >



    </>
  );
}
