import * as React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { MuiMarkdown } from 'mui-markdown';


export default function BlockDisplay({ index, block }) {


    const [open, setOpen] = React.useState(false);
    const [generating, setGenerating] = React.useState(false);
    const [regenerateInstructions, setRegenerateInstructions] = React.useState("");

    const regeneratePrompt = "Given the original content below, regenerate the chapter. Follow these instructions:  ";

    function regenerateChapter() {
        const prompts = '';// [regeneratePrompt + regenerateInstructions + "\n\n" + blockText];
        console.log('Prompts:', prompts);

        setGenerating(true);
        setOpen(false);
        try {
            const requestData = { prompts: prompts };
            fetch(`${process.env.REACT_APP_API_URL}/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then((response) => response.json())
                .then((data) => {
                    //setBlockText(data.blocks.reduce((acc, curr) => acc + curr, '\n\n'));
                })

        }
        finally {
            setGenerating(false);
        }
    }


    useEffect(() => {
        Array.from(document.links)
            .filter(link => link.hostname != window.location.hostname)
            .forEach(link => link.target = '_blank');
    });



    const handleRegenerateOpen = () => {
        console.log('Shold be open now');
        setOpen(true);
    };

    function handleClose() {
        setOpen(false);
    }

    const getMarkdownForBlock = (block, index) => {
        if (true) { // (index === 0 || block.name.startsWith('Conclusion')) {
            return `## ${block.name}\n\n${block.content}`;
        } else {
            return `## Chapter ${index}: ${block.name}\n\n${block.content}`
        }
    }

    return (
        <>
            <Typography sx={{
                '& .MuiTypography-h2': { fontSize: 24, fontWeight: 'bold' },
                '& .MuiTypography-h3': { fontSize: 20, fontWeight: 'bold' },
                '& .MuiTypography-body1': { textAlign: 'justify', margin: '10px 0' },
            }}
                component="div" align="left">
                <MuiMarkdown>
                    {getMarkdownForBlock(block, index)}
                </MuiMarkdown>


            </Typography>

        </>
    );
}