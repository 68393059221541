import * as React from 'react';
import axios from 'axios';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';

export default function AssetUploadDialog({ open, onClose, projectId, refreshAssets }) {

    const { getAccessTokenSilently } = useAuth0();
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a file to upload.');
            return;
        }

        var signed_upload_url = null;
        var cloudstorage_path = null;

        const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/asset_upload_signed_url`;
        const accessToken = await getAccessTokenSilently();
        const headers = { "content-type": "application/json", Authorization: `Bearer ${accessToken}` }

        // get signed url
        await axios.get(url, { headers: headers })
            .then(response => response.data)
            .then(data => {
                console.log(data);
                signed_upload_url = data.signed_url;
                cloudstorage_path = data.file_path;
            });


        console.log(`Uploading file: ${file.name}...`);
        console.log(file);
        const blob = new Blob([file], { type: 'application/octet-stream' });


        try {
            console.log('Signed URL:', signed_upload_url);
            const response = await axios.put(signed_upload_url, blob, {
                headers: {
                    'Content-Type': 'application/octet-stream',
                }
            }).then((response) => {
                console.log('File upload response:', response);
                if (response.status === 200) {
                    const requestData = {
                        filename: file.name,
                        path: cloudstorage_path,
                        content_type: file.type
                    }
                    console.log('Request data:', requestData);
                    const updateAssetsResponse = axios.post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/assets`,
                        requestData,
                        { headers: headers }
                    ).then(() => {
                        refreshAssets()
                    });
                    console.log('Updated assets:', updateAssetsResponse.data);
                    onClose();

                } else {
                    console.error('Error uploading file:', response.data);
                }
            });
        }
        catch (error) {
            console.error('Error uploading file:', error);
            setMessage('Error uploading file.');
        }
    };



    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{ flexGrow: '1' }}
        >
            <DialogTitle>Upload an Asset</DialogTitle>

            <DialogContent>
                <Typography variant='subtitle2'>Supported file types: pdf, png, jpg, txt</Typography>
                <Box sx={{ marginTop: '20px' }}>
                    <input type="file" accept=".pdf, .png, .jpg, .jpeg, .txt" onChange={handleFileChange} />
                    <button onClick={handleUpload}>Upload</button>
                    {message && <p>{message}</p>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
