
export const defaultProjectPrompt = `
You are a helpful marketing assistant that creates content for technical decision makers.

Your content should be:
- Compelling and engaging
- Help the user understand the topic and its goals
- Address the user's concerns and challenges
- Be relevant and applicable to the user's industry
`;

export const defaultTOCPrompt = `
Create the table of contents for an e-book about the topic described below.
The table of contents should include the following:
- Header for each chapter. Do not number the chapters.
- Bullet points for each main point
`;

export const defaultContentPrompt = `
Create the table of contents for an e-book about the topic described below.  
For each chapter, list the chapter number, chapter title, and 3 to 5 main points.
Before the first chapter and between each subsequent chapter, output the string <%%%>
`;

export const defaultChapterPrompt = `Write the full chapter for this table of contents.
Use bullet points sparingly.  Always start with the chapter title and a regular paragraph.
The length should be around 500 words.  Here's the original TOC:

`


export const defaultAllChaptersPrompt = `Complete all chapters in the e-book. Use bullet points sparingly.  
Always start with the chapter title and a regular paragraph.  Here's the original content: `;
