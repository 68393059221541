import { UploadFile } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, formatDistanceToNow, formatRelative, subDays } from 'date-fns'

const columns = [
    {
      field: 'filename',
      headerName: 'Name',
      flex: 1,
      editable: false
    }
  ];
  
  
  
   export default function ArtifactAssetTable({assets, onSelectionChange}) {
    const setSelection = (rows) => {
      console.log(rows)
      onSelectionChange(rows);
    }

    return (

        <DataGrid
          rows={assets}
          columns={columns}
          getRowId={(row) => row._id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
        />

    );
  }